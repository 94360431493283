<template>
  
<div v-html="twitterHtml"></div>
</template>

<script>

export default {
  components: {},
  data: function () {
    return {
       twitterHtml:'' 
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    showAlert: function () {
      // console.log(223,this.uploadInfo);
      return !!this.uploadInfo;
    },
  },
  async mounted() {
     this.twitterHtml = localStorage.getItem('callbackHTML')
   
  },
  
 
};
</script>

<style lang="scss" scoped>

</style>
